import React from 'react'
import { SocialIcon } from 'react-social-icons'

import '../components/component-style/contact.scss'
import Layout from '../components/layout'
import SEO from '../components/seo'

const AboutPage = () => (
    <Layout>
        <SEO title="Contact" />
        <h1>Contact</h1>
        <p>
            Reach out if you have questions, comments, ideas, want to hire me,
            or just want to chat!
        </p>
        <div className="contact-page">
            <form
                method="post"
                action="https://formspree.io/mpzjvdev"
                className="form"
            >
                <div className="contact-form">
                    <div className="contact-info">
                        <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            className="text-input"
                        />
                        <input
                            type="email"
                            name="_replyto"
                            placeholder="Email"
                            className="text-input"
                        />
                    </div>
                    <textarea
                        name="message"
                        placeholder="Message"
                        className="text-area"
                    />
                    <input
                        type="submit"
                        value="Submit"
                        name="submit"
                        className="submit-button"
                    />
                </div>
            </form>
            <div className="socialIcons">
                <SocialIcon
                    url="https://github.com/tom-hightower"
                    className="icon_right"
                    bgColor="#ffd33d"
                />
                <SocialIcon
                    url="https://www.instagram.com/tomlapsing/"
                    className="icon_left"
                    bgColor="#f15a3a"
                />
                <SocialIcon
                    url="https://www.linkedin.com/in/tom-hightower/"
                    className="icon_right"
                />
            </div>
        </div>
    </Layout>
)

export default AboutPage
